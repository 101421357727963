var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['head-box', _vm.$route.name == 'home' ? 'header-position' : ''],style:({
    background:
      _vm.$route.name == 'home'
        ? 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))'
        : '#fff',
  })},[_c('div',{staticClass:"header-pane",style:({
      width: _vm.$route.name == 'home' ? '1510px' : '1200px',
    })},[(_vm.$route.name == 'home')?_c('div',{staticClass:"text"},[_c('img',{attrs:{"src":"/imgs/text2.png","alt":""}})]):_vm._e(),_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.$route.name == 'home' ? '/imgs/logo.png' : '/imgs/logo1.png',"alt":""}})]),_c('div',[_c('ul',{staticClass:"menu-box"},_vm._l((_vm.menuData),function(el){return _c('li',{key:el.name,class:[
            'menu-item',
            _vm.currentName === el.router ||
            (_vm.subMenu.childList
              ? _vm.subMenu.childList.findIndex(
                  function (item) { return item.router === el.router; }
                ) > -1
              : false)
              ? 'menu-item-actived'
              : '' ]},[_c('Dropdown',{attrs:{"trigger":"hover","placement":"bottom"}},[_c('span',{staticClass:"menu-item-title",on:{"click":function (e) {
                  e.stopPropagation();
                  _vm.goThere(el.router, el.url);
                }}},[_vm._v(_vm._s(el.name))]),(el.childList && el.childList.length > 0)?_c('DropdownMenu',{attrs:{"slot":"list"},slot:"list"},[_c('ul',{staticClass:"menu-child-box"},_vm._l((el.childList),function(it){return (it.name != '大数据')?_c('li',{key:it.name},[_c('span',{staticClass:"menu-child-item-title",on:{"click":function (e) {
                        e.stopPropagation();
                        _vm.goThere(it.router, it.url);
                      }}},[_vm._v(_vm._s(it.name))])]):_vm._e()}),0)]):_vm._e()],1)],1)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }