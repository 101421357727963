<template>
  <div
    :class="['head-box', $route.name == 'home' ? 'header-position' : '']"
    :style="{
      background:
        $route.name == 'home'
          ? 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))'
          : '#fff',
    }"
  >
    <div
      class="header-pane"
      :style="{
        width: $route.name == 'home' ? '1510px' : '1200px',
      }"
    >
      <div class="text" v-if="$route.name == 'home'">
        <!-- <p>国家生猪技术创新中心</p>
        <p>——养殖废弃物资源化利用研发中心</p> -->
        <img src="/imgs/text2.png" alt="">
      </div>
      <div class="logo">
        <img
          :src="$route.name == 'home' ? '/imgs/logo.png' : '/imgs/logo1.png'"
          alt=""
        />
      </div>

      <div>
        <ul class="menu-box">
          <li
            :class="[
              'menu-item',
              currentName === el.router ||
              (subMenu.childList
                ? subMenu.childList.findIndex(
                    (item) => item.router === el.router
                  ) > -1
                : false)
                ? 'menu-item-actived'
                : '',
            ]"
            v-for="el in menuData"
            :key="el.name"
          >
            <Dropdown trigger="hover" placement="bottom">
              <span
                class="menu-item-title"
                @click="
                  (e) => {
                    e.stopPropagation();
                    goThere(el.router, el.url);
                  }
                "
                >{{ el.name }}</span
              >
              <DropdownMenu
                slot="list"
                v-if="el.childList && el.childList.length > 0"
              >
                <ul class="menu-child-box">
                  <li v-for="it in el.childList" :key="it.name" v-if="it.name != '大数据'">
                    <span
                      class="menu-child-item-title"
                      @click="
                        (e) => {
                          e.stopPropagation();
                          goThere(it.router, it.url);
                        }
                      "
                      >{{ it.name }}</span
                    >
                  </li>
                </ul>
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>
      </div>
      <!-- <div class="backstage" title="后台管理">
      <img src="imgs/backstage.png" alt="" @click="goBackstage" />
    </div> -->
    </div>
  </div>
</template>

<script>
import CU from "@common/util";
export default {
  name: "",
  data() {
    let matched = this.$route.matched;
    return {
      currentName: matched.length > 2 ? matched[2]?.name : matched[1]?.name,
    };
  },
  computed: {
    menuData() {
      return this.$store.state.permission.menu;
    },
    subMenu() {
      return this.$store.state.permission.subMenu || {};
    },
  },
  methods: {
    getCurrentFromLocation() {
      let matched = this.$route.matched;
      let length = matched.length;
      this.currentName =
        length > 4
          ? matched[4]?.meta?.alignName ?? matched[4]?.name
          : matched[length - 1]?.meta?.alignName ?? matched[length - 1]?.name;
    },
    goThere(router, url) {
      if (router == "onlineShopping") {
        window.location.href = url.split("-")[1];
        return;
      }
      if (router === this.currentName) return;
      this.$router.push({ name: router });
    },

    goBackstage() {
      window.location.href = "http://127.0.0.1/";
    },
  },
  mounted() {
    this.getCurrentFromLocation();
  },
  watch: {
    $route(n) {
      this.getCurrentFromLocation();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ivu-select-dropdown {
  margin: 0;
  padding: 0;
  background: none;
}
.backstage {
  position: absolute;
  right: 30px;
  top: 35px;
  cursor: pointer;
}
.head-box {
  width: 100%;
  height: 82px;
  transition: all 0.5s ease-in-out;
}
.header-position {
  position: absolute;
  z-index: 9;
  color: #fff;
}
.header-pane {
  // width: 1200px;
  height: 82px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text {
    // position: absolute;
    // top: 102px;
    // left: calc((100% - 1200px) / 2);
    // top: 10px;
    // left: 8%;
    // z-index: 999;
    width: 310px;
    // text-align: center;
    // p {
    //   font-size: 18px;
    //   color: #ff0000;
    //   font-weight: bold;
    //   &:first-child {
    //     font-size: 28px;
    //     letter-spacing: 3px;
    //   }
    // }
    img {
      width: 100%;
      height: 60px;
    }
  }
  .logo {
    height: 82px;
    line-height: 82px;
    img {
      vertical-align: middle;
      width: 230px;
      height: 60px;
    }
  }
  .menu-box {
    display: flex;
    .menu-item-actived {
      border-bottom: 2px solid #fff;
    }
    .menu-item {
      height: 82px;
      line-height: 82px;
      margin: 0 12px;
      position: relative;
      .menu-item-title {
        font-weight: bold;
        cursor: pointer;
      }
      .actived {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 56px;
        height: 2px;
        background: #fff;
      }
      &:hover {
        .actived {
          display: inline-block !important;
        }
        border-bottom: 2px solid #fff;
      }
      .menu-child-box {
        width: 140px;
        // padding: 10px 5px;
        background: rgba(0, 0, 0, 0.6);
        > li {
          line-height: 30px;
          text-align: center;
          .menu-child-item-title {
            font-size: 14px;
            color: #cccccc;
            cursor: pointer;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>